<template>
  <section id="form-encuesta-step-2-question-type-1">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-8">
            <label class="form-label text-secondary">
              Ingresa la pregunta
            </label>
            <input 
              class="form-control input-custom"
              maxlength="170" 
              type="text" 
              v-model="pregunta"
            />
          </div>
          <div class="col-12 col-lg-4">
            <label class="form-label text-secondary">
                Tipo de selección
                <span 
                  class="link-cursor"
                  content="Esta selección le permitirá al usuario elegir una o múltiples respuestas."
                  v-tippy="{ arrow: true }">
                <i 
                  class="fa-regular fa-circle-question"
                >
                </i>
                </span>
            </label>
            <v-select 
              class="selvue-custom"
              v-model="tipo_pregunta" 
              label="name" 
              :reduce="name => name.id"
              :options="opciones_tipo_seleccion" 
              :clearable="false"
            >
            </v-select>
          </div>
        </div>
        <div class="border-bottom border-1 mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-light border d-flex align-items-center justify-content-between">
          <label 
            for="respuestaCorrecta"
            class="form-check-label link-cursor text-secondary"
          >
          ¿Deseas seleccionar una respuesta correcta?
          </label>
          <div class="form-check form-switch">
            <input 
              id="respuestaCorrecta"
              class="form-check-input link-cursor" 
              type="checkbox" 
              role="switch"
              v-model="respuestaCorrecta"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div 
        v-for="(alt, i) in alternative_list" 
        :key="i"
        class="col-6 position-relative mb-3">
        <div class="input-group">
          <div 
            v-if="respuestaCorrecta == true"
            class="input-group-text">
            <input 
              class="form-check-input mt-0" 
              type="radio" 
              id="checkRespuestaCorrecta"
              name="checkRespuestaCorrecta" 
              @change="addCorrecta(i)"
            >
          </div>
          <input 
            type="text"
            class="form-control input-custom"
            :placeholder="'Alternativa ' + (i + 1)"
            v-model="alternative_text[i]"
            @input="alternative_text[i].length > max_chars ? alternative_text[i] = alternative_text[i].slice(0,max_chars):''" 
          >
        </div>
        <a 
          href="javascript:"
          v-show="i > 1" 
          content="Elimina esta alternativa" 
          v-tippy="{ arrow: true }"
          @click="deleteAlternative(i)"
          class="input-custom-icon-right"
          >
          <i class="fa-solid fa-trash-can"></i>
        </a>
        <div class="form-text text-end">
          {{checkLenghtAlt(i)}}/{{max_chars}}
        </div>
      </div>
      <div class="col-6 mb-3">
        <a 
          @click="addAlternatives()"
          class="btn btn-custom btn-custom-color-white border justify-content-between">
          Agregar alternativa
          <i class="fa-solid fa-circle-plus color-secondary"></i>
        </a>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="cleanValues" 
          v-if="boton_enviar == 'Editar'"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!comprobar()"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus"
    />

<!--     <div class="add-encuesta__component-dos">
      <div class="">
        <div class="main-add__first d-flex align-items-end justify-content-between">
          <label for="" class="main-add__label-large">
            Texto pregunta
            <input class="main-add__input-large" maxlength="170" type="text" placeholder=""
              v-model="pregunta"/>
          </label>
          <div class="main-add__short-wrapper">
            <label class="main-add__label">
              Tipo de selección
              <span>
                <font-awesome-icon class="main-add__label--icon"
                  content="Esta selección le permitirá al usuario elegir una o múltiples respuestas."
                  v-tippy="{ arrow: true }" icon="question"></font-awesome-icon>
              </span>
              <v-select class="main-add__input-short" v-model="tipo_pregunta" label="name" :reduce="name => name.id"
                :options="opciones_tipo_seleccion" :clearable="false">
              </v-select>
            </label>
          </div>
        </div>
        <div class="main-add__divider-sections"></div>
        <div class="d-flex align-items-center mb-2">
          <label class="form-check-label survey-add__step-title me-2" for="flexSwitchCheckDefault">¿Deseas seleccionar una
            respuesta correcta?</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="respuestaCorrecta" />
          </div>
        </div>

        <div class="main-add__alternatives-section row" id="inputs-wrapper">
          <label 
            for="" 
            class="col-6 d-flex align-items-center" 
            v-for="(alt, i) in alternative_list" 
            :key="i"
          >
            <div class="main-add__input-container mt-2">
              <input 
                class="main-add__input-alternatives" 
                type="text" 
                :placeholder="'Alternativa ' + (i + 1)"
                v-model="alternative_text[i]"
                @input="alternative_text[i].length > max_chars ? alternative_text[i] = alternative_text[i].slice(0,max_chars):''" 
              />
              <div class="main-add__input-alternatives--counter">
                {{checkLenghtAlt(i)}}/{{max_chars}}
              </div>
              <img 
                v-show="i > 1" 
                content="Elimina esta alternativa" 
                v-tippy="{ arrow: true }"
                class="main-add__input-alternatives--icon me-3" 
                @click="deleteAlternative(i)"
                src="@/assets/img/Eliminar.svg"
                alt=""
              >
            </div>
            <div 
              class="form-check main-add__form-check" 
              v-show="respuestaCorrecta == true"
            >
              <input 
                class="form-check-input p-0 mx-2" 
                type="radio" 
                name="flexRadioDisabled" 
                id="flexRadioDisabled"
                value="enlaces" 
                @click="addCorrecta(i)" 
              />
            </div>
          </label>
          <div 
            class="main-add__add-alternative col-6 mt-2"
            @click="addAlternatives()"
          >
            <p class="main-add__add-alternative--text my-0">
              Agregar alternativa
            </p>
            <font-awesome-icon 
              class="main-add__add-alternative--icon me-3" 
              icon="plus-circle"
            />
          </div>
        </div>
      </div>
      <div class="main-add__buttons-container pb-3">
        <button 
          class="main-add__button-clean" 
          @click="cleanValues" 
          v-if="boton_enviar == 'Editar'"
        >
          Cancelar
        </button>
        <button 
          class="main-add__button-upload" 
          :class="comprobar() && 'active'" 
          @click="addOrEdit()"
        >
          {{ boton_enviar }}
        </button>
      </div>
      <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="acceptStatus" />
    </div> -->
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
export default {
  components: {
    Status,
  },
  props: {
    index: {
      type: Number,
    },
    edit: {
      type: Object,
    },
  },
  data() {
    return {
      max_chars: 80,
      pregunta: "",
      open_select: false,
      cat_selected: "2",
      tipoSeleccion: ["Única", "Multiple"],
      tipo_pregunta: "2",
      respuestaCorrecta: false,
      alternative_list: [
        { id: 1, text: "Alternativa 1", correcta_alternativa: "", },
        { id: 2, text: "Alternativa 2", correcta_alternativa: "", },
      ],
      alternative_text: [],
      boton_enviar: "Añadir",
      // modal status
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      id_pregunta: "0",
      opciones_edit: [],
      opciones_tipo_seleccion: [
        {
          id: "2",
          name:'Única'
        },
        {
          id: "3",
          name: 'Múltiple'
        }
      ]
    };
  },
  created() {
    let data = localStorage.getItem("encuestaStore");
    if (data != null) {
      this.encuestaStore = JSON.parse(data);
    }
  },
  methods: {
    checkLenghtAlt(i) {
      console.log('I', i);
      console.log(this.alternative_text);
      if (this.alternative_text[i] != undefined && this.alternative_text[i] != null) {
        return this.alternative_text[i].length;
      } else {
        return 0;
      }
    },
    refresh(pregunta) {
      console.log("Pregunta editar", pregunta);
      if (pregunta.tipo_pregunta == "2" || pregunta.tipo_pregunta == "3") {
        this.id_pregunta = pregunta.id_pregunta;
        this.pregunta = pregunta.pregunta;
        this.cat_selected = pregunta.tipo_pregunta;
        this.respuestaCorrecta = this.inputCorrect(pregunta.opciones);
        this.alternative_list = this.inputAlt(pregunta.opciones);
        this.alternative_text = this.inputText(pregunta.opciones);
        this.opciones_edit = pregunta.opciones;
        this.boton_enviar = "Guardar";
      }
    },
    // Rellenar campos
    inputCorrect(opciones) {
      let correcta = false;
      for (let i = 0; i < opciones.length; i++) {
        if (opciones[i].correcta_alternativa == "0") {
          correcta = true;
        }
      }
      return correcta;
    },
    inputAlt(opciones) {
      let alternativas = [];
      for (let i = 0; i < opciones.length; i++) {
        if(opciones[i].opcion != 'delete') {
          alternativas.push({
            id: i,
            text: opciones[i].opcion,
            correcta_alternativa: opciones[i].correcta_alternativa,
          });
        }
      }
      return alternativas;
    },
    inputText(opciones) {
      let text = [];
      for (let i = 0; i < opciones.length; i++) {
        if(opciones[i].opcion != 'delete'){
          let texto = opciones[i].opcion;
          text.push(texto);
        }
      }
      return text;
    },
    // Limpiar
    cleanValues() {
      this.pregunta = "";
      (this.open_select = false), (this.cat_selected = "2");
      this.alternative_text = [];
      this.alternative_list = [
        { id: 1, text: "Alternativa 1", correcta_alternativa: "", },
        { id: 2, text: "Alternativa 2", correcta_alternativa: "", },
      ];
      this.tipo_pregunta = "2";
      this.respuestaCorrecta = false;
      this.boton_enviar = "Añadir";
      this.$emit("cancel");
      
    },
    // Controlador de añadir o editar
    addOrEdit() {
      if (this.comprobar() == true) {
        if (this.boton_enviar == "Añadir") {
          this.addQuestion();
        } else {
          this.editQuestion();
        }
      }
    },
    addQuestion() {
      if (this.encuestaStore.preguntas == undefined) {
        this.encuestaStore.preguntas = [];
      }
      let pregunta = {
        id_pregunta: "0",
        tipo_pregunta: this.tipo_pregunta,
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: this.optionsFormat(), // agregar opciones
      };
      console.log('DATA PREGUNTA', pregunta);
      this.updateQuestions();
      this.encuestaStore.preguntas.push(pregunta);
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("add-pregunta");
    },
    editQuestion() {
      let pregunta = {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: this.tipo_pregunta,
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: this.optionsEditFormat(), // agregar opciones
      };
      console.log("pregunta que envio: ", pregunta.opciones);
      this.updateQuestions();
      this.encuestaStore.preguntas[this.index] = pregunta;
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("edit-pregunta");
      this.boton_enviar = "Añadir";
    },
    optionsFormat() {
      this.altFormat();
      let options = [];
      for (let i = 0; i < this.alternative_list.length; i++) {
        options.push({
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: this.alternative_list[i].correcta_alternativa,
          opcion: this.alternative_list[i].text,
        });
      }
      // console.log("optionsFormat: ", options);
      return options;
    },
    optionsEditFormat() {
      let antiguo = this.opciones_edit;
      let nuevo = this.optionsFormat();
      console.log("+++++++++++++++++++++++++");
      console.log("antiguo", antiguo);
      console.log("nuevo: ", nuevo);
      // cambiando solo ids alternativas antiguas
      let optionEdit = [];
      // son iguales
      if (antiguo.length == nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: nuevo[i].correcta_alternativa,
            opcion: nuevo[i].opcion,
          });
        }
      }
      // antiguo < nuevo
      if (antiguo.length < nuevo.length) {
        for (let i = 0; i < antiguo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
      }
      // antiguo > nuevo
      if (antiguo.length > nuevo.length) {
        for (let i = 0; i < nuevo.length; i++) {
          nuevo[i].id_alternativa = antiguo[i].id_alternativa;
        }
        optionEdit = nuevo;
        for (let i = nuevo.length; i < antiguo.length; i++) {
          optionEdit.push({
            id_alternativa: antiguo[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: "",
            opcion: "delete",
          });
        }
      }
      console.log("opciones actualizadas: ", optionEdit);
      console.log("+++++++++++++++++++++++++");
      return optionEdit;
    },
    altFormat() {
      for (let i = 0; i < this.alternative_list.length; i++) {
        this.alternative_list[i].text = this.alternative_text[i];
        if (this.respuestaCorrecta == false) {
          this.alternative_list[i].correcta_alternativa = "1";
        }
      }
    },
    updateQuestions() {
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if (encuesta_actual != this.encuestaStore) {
        this.encuestaStore = encuesta_actual;
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },
    // Funciones de template
    toggleSelect() {
      if (this.open_select) this.open_select = false;
      else this.open_select = true;
    },
    setTipoSeleccion(status) {
      if (status === "Única") this.tipo_pregunta = "2";
      if (status === "Multiple") this.tipo_pregunta = "3";
      this.cat_selected = this.tipo_pregunta;
      this.open_select = false;
    },
    addCorrecta(index) {
      for (let i = 0; i < this.alternative_list.length; i++) {
        if (i == index && this.respuestaCorrecta == true) {
          this.alternative_list[i].correcta_alternativa = "1";
        } else {
          this.alternative_list[i].correcta_alternativa = "0";
        }
      }
    },
    addAlternatives() {
      let alternativa = {
        id: this.alternative_list.length + 1,
        text: `Alternativa ${this.alternative_list.length + 1}`,
        correcta_alternativa: "",
      };
      this.alternative_list.push(alternativa);
    },
    comprobar() {
      let alternativas = false;
      if (this.alternative_text.length == this.alternative_list.length) {
        alternativas = true;
      } else {
        alternativas = false;
      }
      if (this.pregunta != "" && alternativas == true) {
        return true;
      } else {
        return false;
      }
    },
    // Eliminar alternativa
    deleteAlternative(i){
      console.log('Index alternativa', i);
      this.alternative_list.splice(i, 1);
      this.alternative_text.splice(i,1);
      console.log("RESULT", this.alternative_text, this.alternative_list);
    },
  },
};
</script>
