<template>

<section 
    id="form-encuesta-step-2" 
    class="d-flex flex-column flex-grow-1"
  >
  <div class="row flex-grow-1 mt-3">
    <div class="col-12 col-lg-6">
      <label class="form-label text-secondary">
        Selecciona el día de inicio
      </label>
      <v-date-picker 
          style="width: 100%" 
          color="indigo" 
          class="date" 
          :min-date="getActualDate()" 
          :max-date="fechaFin"
          v-model="fechaInicio"
        />
    </div>
    <div class="col-12 col-lg-6 mt-3 mt-lg-0">
      <label class="form-label text-secondary">
        Selecciona el día de término
      </label>
      <v-date-picker 
          style="width: 100%" 
          color="indigo" 
          class="date" 
          :min-date="fechaInicio" 
          v-model="fechaFin"
        />
    </div>
  </div>

  <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
          <button 
            class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
            @click="backStep()">
            Volver
          </button>
          <button
            class="btn btn-custom-color-blue border-round-50 mw-100"
            @click="addOrEdit()"
            :disabled="!comprobar()"
          >
          {{ crear_editar}}
          </button>
        </div>
      </div>
    </div>

    <Question 
      v-if="open_question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelAdd"
      @accept="addPoll" 
    />
    <Question
      v-if="open_question_modal_edit" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelEdit"
      @accept="editPoll"
    />
    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus"
    />
    <Status 
      v-if="open_comprobar_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="statusComprobar"
    />
    <Spinner v-if="show_spinner"/>


<!--   <div class="d-flex">
    <div class="encuestas-stept__left my-3 d-flex">
      <div class="encuestas-stept__left--primero">
        <h3 class="survey-add__step-title text-start ">Selecciona el día de inicio</h3>
        <v-date-picker 
          style="width: 100%" 
          color="indigo" 
          class="date" 
          :min-date="getActualDate()" 
          :max-date="fechaFin"
          v-model="fechaInicio"
        />
      </div>
      <div class="encuestas-stept__left--segundo ms-4" v-show="fechaInicio">
        <h3 class="survey-add__step-title text-start ">Selecciona el día de término</h3>
        <v-date-picker 
          style="width: 100%" 
          color="indigo" 
          class="date" 
          :min-date="fechaInicio" 
          v-model="fechaFin"
        />
      </div>
    </div>
    <div
      class="encuestas-stept__right text-start me-4 my-3 d-flex align-items-center"
    >
      <label
        class="survey-add__step-title form-check-label me-5"
        for="flexSwitchCheckChecked"
        >Enviar alerta push</label
      >
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          v-model="alertaPush"
        />
      </div>
    </div>
    
    <div class="buttons-add-news">
      <button class="survey-add__back-button me-3" @click="backStep">Atrás</button>
      <button class="survey-add__next-button" :class="comprobar() && 'active'" @click="addOrEdit()">
        {{ crear_editar}}
      </button>
  
    </div>
    <Question v-if="open_question_modal" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelAdd"
      @accept="addPoll" />
    <Question v-if="open_question_modal_edit" :msg="question_modal_msg" :hideCancel="false" @cancel="cancelEdit"
      @accept="editPoll" />
    <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="acceptStatus" />
    <Status v-if="open_comprobar_status" :msg="modal_status_msg" :status="modal_status" @close="statusComprobar" />
    <Spinner v-if="show_spinner" />
  </div> -->
</section>

</template>

<script>
import { mapActions, mapState } from "vuex";
//import { EDIT_ENCUESTA_ID } from "../../apollo/mutations";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";

export default {
  components: {
    Question,
    Status,
    Spinner
  },
  data() {
    return {
      show_spinner: false,
      fechaInicio: "",
      fechaFin: "",
      encuesta: {},
      alertaPush: false,
      // Modales
      open_question_modal: false,
      question_modal_msg: "",
      open_question_modal_edit: false,
      open_comprobar_status: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: false,
      crear_editar:"",

      eliminadas: [],
    };
  },
  created(){
    if(this.$route.params.id != undefined && this.$route.params.id != null) {
      this.crear_editar = "Editar";
    } else {
      this.crear_editar = "Crear";
    }
    let data = JSON.parse(localStorage.getItem("encuestaStore"));
    if (data!=null) {
      this.encuesta = data;
      this.min_date = this.encuesta.fecha_inicio
      this.fechaInicio = this.encuesta.fecha_inicio;
      this.fechaFin = this.encuesta.fecha_fin;
    }
    console.log("Paso 3, data:", this.fechaInicio);
    let data2 = JSON.parse(localStorage.getItem("preguntas_deleted"));
    if(data2 != null){
      this.eliminadas = data2;
      console.log("Borradas: ", this.eliminadas);
    }
    // ir a paso 2
    if(this.encuesta.preguntas.length == 0){
      this.backStep()
    }
  },
  computed: {
    ...mapState("encuestasModule", ["encuestaStore", "preguntas_deleted"]),
  },
  methods: {
    ...mapActions("encuestasModule",
    ["createEncuestaAction",
    "editEncuestaAction", "crearEncuesta", "editarEncuesta"]),
    // Atras
    getActualDate() {
      let today = new Date();
      // let day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
      // let month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
      // let year = today.getFullYear();
      //return year + '-' + month + '-' + day
      return today;
    },
    backStep() {
      this.encuesta.fecha_inicio = this.fechaInicio;
      this.encuesta.fecha_fin = this.fechaFin;
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuesta));
      this.$emit("back-encuesta");
    },
    // Publicar o editar
    addOrEdit(){
      if(this.comprobar() == true){
        // console.log("Publicar encuesta: ", this.encuesta.preguntas);
        if(this.$route.params.id != undefined && this.$route.params.id != null){
          this.question_modal_msg = "¿Está seguro de editar la encuesta?";
          this.open_question_modal_edit = true;
        } else {
          // this.question_modal_msg = "¿Está seguro de publicar la encuesta?";
          // this.open_question_modal = true;
          this.addPoll();
        }
      }
    },
    // Publicar
    async addPoll(){
      this.show_spinner = true;
      let data_ = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.encuesta.nombreEncuesta,
        descripcion: this.encuesta.descripcion,
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        tipoEncuesta: "1",
        urlEncuesta: "",
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.encuesta.anonima,
        tipoSegmentacion: this.encuesta.tipoSegmentacion,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: this.encuesta.preguntas,
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.encuesta.segmentaciones,
      }
      await this.crearEncuesta(data_).then(res=>{
        this.show_spinner = false;
        if(res){
          this.$toast.open({
            message: "Tú encuesta ha sido añadido correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }else{
          this.$toast.open({
            message: "El proceso de creación de encuesta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        localStorage.removeItem("encuestaStore");
        localStorage.removeItem("preguntas_deleted");
        this.$router.push({ name: "encuestas-list" });
      }).catch( err => {
        console.log("falla: add Encuesta: ",err);
        this.$toast.open({
            message: "El proceso de creación de encuesta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
      });
      this.show_spinner = false;
    },
    cancelAdd() {
      console.log("cancel");
      this.open_question_modal = false;
    },
    // Editar
    async editPoll(){
      // console.log("aceptar");
      this.show_spinner = true;
      console.log("preguntas antes de agregar eliminadas: ", this.encuesta.preguntas);
      this.encuesta.preguntas = this.agregarEliminadas();
      console.log("preguntas despues de agregar eliminadas: ", this.encuesta.preguntas);
      let data_ = {
        idEncuesta: this.$route.params.id,
        idEmpresa:  this.$ls.get("user").id_empresa_fk,
        nombreEncuesta: this.encuesta.nombreEncuesta,
        descripcion: this.encuesta.descripcion,
        fechaInicio:  this.fechaInicio,
        fechaFin: this.fechaFin,
        tipoEncuesta: "1",
        urlEncuesta: "",
        idUsuario: this.$ls.get("user").id_usuario,
        anonima: this.encuesta.anonima,
        tipoSegmentacion: this.encuesta.tipoSegmentacion,
        alertaPush: this.boolToString(this.alertaPush),
        preguntas: this.encuesta.preguntas,
        usuarios: [],
        enlaces: [],
        correoContactos: [],
        segmentaciones: this.encuesta.segmentaciones,
      }
      await this.editarEncuesta(data_).then(res=>{
        this.show_spinner = false;
        if(res){
          this.$toast.open({
            message: "Tú encuesta ha sido añadido correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }else{
          this.$toast.open({
            message: "El proceso de creación de encuesta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        localStorage.removeItem("encuestaStore");
        localStorage.removeItem("preguntas_deleted");
        this.$router.push({ name: "encuestas-list" });
      }).catch( err => {
        console.log("falla: add Encuesta: ",err);
        this.$toast.open({
            message: "El proceso de creación de encuesta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
      });
      this.show_spinner = false;
    },

    agregarEliminadas(){
      let preguntas = this.encuesta.preguntas;
      if(this.eliminadas.length != []) {
        this.eliminadas.forEach(a => {
          preguntas.push({
            id_pregunta: a.id_pregunta,
            tipo_pregunta : a.tipo_pregunta,
            pregunta: "delete",
            editable: "1",
            calificacion_puntos: "0",
            calificacion_estrellas: "0",
            opciones: [],
          });
        });
      }
      console.log("preguntas con eliminadas: ", preguntas );
      return preguntas;
    },
    cancelEdit(){
      console.log("cancelar");
      this.open_question_modal_edit = false;
    },
    // Funciones
    boolToString(valor){
      if(valor == true) return "1";
      else return "0";
    },
    stringToBool(valor){
      if(valor == "1") return true;
      else return false;
    },
    comprobar(){
      return (this.fechaInicio != "" && this.fechaFin != "");
    },
    // Status
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
      this.$router.push({
        name: "encuestas-list",
      });
    },
    statusComprobar(){
      this.open_comprobar_status = false;
    },
  },
};
</script>
