<template>

<section id="form-encuesta-escalalineal">
  <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="pregunta"
          >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="border-bottom mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Respuesta</label>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="form-label text-secondary">Desde</label>
            <v-select
              class="selvue-custom"
              :options="from"
              v-model="opcion"
              @input="setTipoSeleccionFrom(opcion)"
              :clearable="false"
            ></v-select>
          </div>
          <div class="col-12 col-lg-6">
            <label class="form-label text-secondary">Hasta</label>
            <v-select
              class="selvue-custom"
              :options="to"
              v-model="opcionTo"
              @input="setTipoSeleccionTo(opcionTo)"
              :clearable="false"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="cleanValues()"
          v-if="boton_enviar == 'Editar'"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!comprobar()"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus" 
    />

<!--   <div class="add-encuesta__component-dos">
    <div class="">
      <div
        class="main-add__first d-flex align-items-end justify-content-between"
      >
        <label for="" class="main-add__label">
          Texto Pregunta
          <div class="main-add__input-complete">
            <input maxlength="170" type="text" v-model="pregunta" />
          </div>
        </label>
      </div>
      <hr />
      <p class="main-add__text-response">Respuesta</p>
      <div class="d-flex mt-2">
        <div class="d-flex align-items-center">
          <div class="main-add__text-label">
            <div>
              De:
            </div>
            <div class="ms-2">
              <v-select
              class="ms-2"
              :options="from"
              v-model="opcion"
              @input="setTipoSeleccionFrom(opcion)"
              :clearable="false"
            ></v-select>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="main-add__text-label ps-3">
              a:
              <v-select
                class=" ms-2"
                :options="to"
                v-model="opcionTo"
                @input="setTipoSeleccionTo(opcionTo)"
                :clearable="false"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
    <div class="main-add__buttons-container">
      <button class="main-add__button-clean" @click="cleanValues()" v-if="boton_enviar == 'Editar'">
        Cancelar
      </button>
      <button class="main-add__button-upload" :class="comprobar() && 'active'" @click="addOrEdit()">
        {{ boton_enviar }}
      </button>
    </div>
    <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="acceptStatus" />
  
    </div>
  </div> -->
</section>


</template>

<script>
import Status from "../Modales/Status.vue";
export default {
  components: { Status },
  props: {
    index: {
      type: Number,
    },
    edit: {
      type: Object,
    },
  },
  data() {
    return {
      pregunta: "",
      preguntas: [],
      opcion: "0",
      opcionTo: "2",
      // modal status
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      from: [0, 1],
      to: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      open_select_from: false,
      open_select_to: false,
      cat_selected: 2,
      cat_selected_from: 0,
      opciones: [],
      boton_enviar: "Añadir",
      id_pregunta: "0",
    };
  },
  created() {
    let data = localStorage.getItem("encuestaStore");
    if (data != null) {
      this.encuestaStore = JSON.parse(data);
    }
  },
  methods: {
    refresh(pregunta) {
      console.log("Pregunta editar", pregunta);
      if (
        pregunta.tipo_pregunta == "4" &&
        pregunta.calificacion_estrellas == "0"
      ) {
        this.id_pregunta = pregunta.id_pregunta;
        this.pregunta = pregunta.pregunta;
        this.cat_selected_from = parseInt(pregunta.opciones[0].opcion);
        this.cat_selected = parseInt(
          pregunta.opciones[pregunta.opciones.length - 1].opcion
        );
        console.log("a", this.cat_selected);
        console.log("de", this.cat_selected_from);

        this.opcion = this.cat_selected_from.toString();
        this.opcionTo = this.cat_selected.toString();
        this.boton_enviar = "Guardar";
      }
    },
    // Limpiar
    cleanValues() {
      this.pregunta = "";
      this.opcion = "0";
      this.opcionTo = "2";
      this.cat_selected_from = 0;
      this.cat_selected = 2;
      this.boton_enviar = "Añadir";
      this.$emit("cancel");
    },
    addOrEdit() {
      if (this.comprobar() == true) {
        if (this.boton_enviar == "Añadir") {
          this.addQuestion();
        } else {
          this.editQuestion();
        }
      }
    },
    // Controlador de añadir o editar
    addQuestion() {
      if (this.encuestaStore.preguntas == undefined) {
        this.encuestaStore.preguntas = [];
      }
      let pregunta = {
        id_pregunta: "0",
        tipo_pregunta: "4",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "1",
        calificacion_estrellas: "0",
        opciones: this.optionsFormat(),
      };
      this.updateQuestions();
      this.encuestaStore.preguntas.push(pregunta);
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("add-pregunta");
    },
    editQuestion() {
      let pregunta = {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "4",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "1",
        calificacion_estrellas: "0",
        opciones: this.optionsFormat(),
      };
      this.updateQuestions();
      this.encuestaStore.preguntas[this.index] = pregunta;
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("edit-pregunta");
      this.boton_enviar = "Añadir";
    },
    optionsFormat() {
      let options = [];
      for (let i = this.cat_selected_from; i <= this.cat_selected; i++) {
        options.push({
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: "1",
          opcion: `${i}`,
        });
      }
      return options;
    },
    updateQuestions() {
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if (encuesta_actual != this.encuestaStore) {
        this.encuestaStore = encuesta_actual;
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },
    // Funciones de template
    toggleSelectFrom() {
      if (this.open_select_from) this.open_select_from = false;
      else this.open_select_from = true;
    },
    setTipoSeleccionFrom(status) {
      if (status == 0) {
        this.opcion = 0;
        this.to = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      }

      if (status == 1) {
        this.opcion = 1;
        this.to = [2, 3, 4, 5, 6, 7, 8, 9, 10];
      }
      this.cat_selected_from = this.opcion;
      setTimeout(() => {
        this.open_select_from = false;
      }, 100);
    },
    toggleSelectTo() {
      if (this.open_select_to) this.open_select_to = false;
      else this.open_select_to = true;
    },
    setTipoSeleccionTo(status) {
      let escalaArray = [];
      for (let i = this.opcion; i <= status; i++) {
        escalaArray.push(i.toString());
      }
      this.cat_selected = parseInt(escalaArray[escalaArray.length - 1]);
      this.opciones = escalaArray;
      setTimeout(() => {
        this.open_select_to = false;
      }, 100);
    },
    comprobar() {
      if (this.pregunta != "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style></style>
