<template>

<section 
    id="form-encuesta-step-2" 
    class="d-flex flex-column flex-grow-1"
  >
  <div class="row flex-grow-1 mt-3">
    <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Selecciona elemento</h5>
      <div class="card flex-grow-1">
        <div class="card-body">
          <h6 class="font-main-bold color-main text-center">Tipo de Pregunta</h6>
          <div class="list-group">
            <a 
              v-for="pregunta in preguntas"
              :key="pregunta.id"
              href="javascript:" 
              @click="show_component = pregunta.component"
              class="list-group-item list-group-item-action border border-2 rounded-pill mb-2"
              :class="pregunta.component === show_component ? 'link-primary border-primary' : ' text-secondary'"
            >
              <i :class="pregunta.icon"></i>
              {{ pregunta.label }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Añade contenido</h5>
      <div class="card flex-grow-1">
        <div class="card-body d-flex flex-column">
          <h6 class="font-main-bold color-main text-center">Contenido interior pregunta</h6>
          <AddAlternativas 
            ref="alternativas_"
            @add-pregunta="updateQuestions"
            @edit-pregunta ="editQuestions"
            @cancel = "cancelQuestion"
            :index="index_pregunta"
            :class="show_component == 'alternativas' ? 'd-flex flex-column flex-grow-1' : ''"
            v-show="show_component == 'alternativas'"
            />
            <AddCalificacion 
            ref="calificacion_"
            @add-pregunta="updateQuestions" 
            @edit-pregunta ="editQuestions"
            @cancel = "cancelQuestion"
            :index="index_pregunta"
            :class="show_component == 'calificacion' ? 'd-flex flex-column flex-grow-1' : ''"
            v-show="show_component == 'calificacion'" 
            />
            <AddEscalaLineal 
            ref="escalaLineal_"
            @add-pregunta="updateQuestions" 
            @edit-pregunta ="editQuestions"
            @cancel = "cancelQuestion"
            :index="index_pregunta"
            :edit="pregunta_edit"
            :class="show_component == 'escalalineal' ? 'd-flex flex-column flex-grow-1' : ''"
            v-show="show_component == 'escalalineal'" 
            />
            <AddDesarrollo 
            ref="desarrollo_"
            @add-pregunta="updateQuestions"
            @edit-pregunta ="editQuestions"
            @cancel = "cancelQuestion"
            :index="index_pregunta"
            :edit="pregunta_edit" 
            :class="show_component == 'desarrollo' ? 'd-flex flex-column flex-grow-1' : ''"
            v-show="show_component == 'desarrollo'" 
            />
            <AddSatisfaccion 
            ref="satisfaccion_"
            @add-pregunta="updateQuestions" 
            @edit-pregunta ="editQuestions"
            @cancel = "cancelQuestion"
            :index="index_pregunta"
            :edit="pregunta_edit"
            :class="show_component == 'satisfaccion' ? 'd-flex flex-column flex-grow-1' : ''"
            v-show="show_component == 'satisfaccion'" 
            />
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
      <h5 class="font-main text-secondary">Edita y reorganiza</h5>
      <div class="card flex-grow-1">
        <div class="card-body">
          <h6 class="font-main-bold color-main text-center">Historial preguntas</h6>
          <ul
            class="list-group"
            :animation="200"
            :list="encuestaStore.preguntas"
            group="task"
            ghost-class="ghost"
          >
            <li 
              v-for="(question, $index) in encuesta.preguntas" 
              :key="$index"
              class="list-group-item d-flex justify-content-between align-items-center border text-secondary rounded-pill mb-2 link-custom">
              <span>
                <span class="badge color-main border border-primary rounded-pill me-1">
                  {{ $index + 1 }}
                </span>
                {{ nameQuestion(question) }}
              </span>
              <span class="link-custom-hover">
                <a 
                  href="javascript:"
                  @click="editQuestion($index)"
                  class="me-2"
                >
                  <i class="fa-solid fa-pencil color-brown"></i>
                </a>
                <a 
                  href="javascript:"
                  @click="deleteQuestion($index)"
                >
                  <i class="fa-solid fa-trash-can color-red"></i>              
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
          <button 
            class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
            @click="backStep()">
            Volver
          </button>
          <button
            class="btn btn-custom-color-blue border-round-50 mw-100"
            @click="nextStep()"
            :disabled="!comprobar()"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>

    <Status
      v-if="open_comprobar_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="statusComprobar"
    />
</section>

</template>

<script>
import AddAlternativas from "./AddAlternativas.vue";
import AddCalificacion from "./AddCalificacion.vue";
import AddEscalaLineal from "./AddEscalaLineal.vue";
import AddDesarrollo from "./AddDesarrollo.vue";
import AddSatisfaccion from "./AddSatisfaccion.vue";
import { mapState } from "vuex";
import Status from "../Modales/Status.vue";
export default {
  components: {
    AddAlternativas,
    AddCalificacion,
    AddEscalaLineal,
    AddDesarrollo,
    AddSatisfaccion,
    Status,
  },
  data() {
    return {
      show_component: "alternativas",
      open_select: false,
      tipoSeleccion: ["Única", "Multiple"],
      cat_selected: "2",
      encuesta: {},
      index_pregunta: -1,
      pregunta_edit: {},
      open_comprobar_status: false,
      modal_status_msg: "",
      modal_status: false,

      habilitadoA: true,
      habilitadoC: true,
      habilitadoEL: true,
      habilitadoD: true,
      habilitadoS: true,

      // pregunta_eliminada: {},
      eliminadas: [],

      //FF
      preguntas: [
        {
        id: 1,
        label: "Alternativas",
        component: "alternativas",
        icon: "fa-solid fa-list"
        },
        {
        id: 2,
        label: "Calificación",
        component: "calificacion",
        icon: "fa-regular fa-star"
        },
        {
        id: 3,
        label: "Escala líneal",
        component: "escalalineal",
        icon: "fa-solid fa-ellipsis"
        },
        {
        id: 4,
        label: "Desarrollo",
        component: "desarrollo",
        icon: "fa-solid fa-align-left"
        },
        {
        id: 5,
        label: "Satisfacción",
        component: "satisfaccion",
        icon: "fa-regular fa-face-smile"
        },
      ]
    };
  },
  created() {
    // Encuesta
    let data = JSON.parse(localStorage.getItem("encuestaStore"));
    if (data != null) {
      this.encuesta = data;
    }
    console.log("Paso 2, data:", data);
    // Eliminadas
    let data2 = JSON.parse(localStorage.getItem("preguntas_deleted"));
    if (data != null) {
      this.eliminadas = data2;
    }
  },
  computed: {
    ...mapState("encuestasModule", ["encuestaStore", "preguntas_deleted"]),
  },
  methods: {
    // ...mapActions("encuestasModule", ["addPreguntasDeleted"]),
    // Siguiente
    nextStep() {
      if (this.comprobar() == true) {
        this.$emit("add-encuesta");
      }
    },
    // Atras
    backStep() {
      this.$emit("back-encuesta");
    },
    // Comprobar
    comprobar() {
      return this.encuesta.preguntas.length != 0;
    },
    updateQuestions() {
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if (encuesta_actual != this.encuesta) {
        this.encuesta = encuesta_actual;
        console.log("ENCUESTA",this.encuesta.preguntas);
      }
    },
    // nombre a mostrar en sección de preguntas
    nameQuestion(question){
      if(question.tipo_pregunta == "1") return 'Desarrollo';
      if(question.tipo_pregunta == "2" || question.tipo_pregunta == "3") return 'Alternativas';
      if(question.tipo_pregunta == "4" && question.calificacion_estrellas == "1") return 'Calificación';
      if(question.tipo_pregunta == "4" && question.calificacion_estrellas == "0") return 'Escala lineal';
      if(question.tipo_pregunta == "5") return 'Satisfacción';
    },
    editQuestions(){
      this.index = -1;
      this.pregunta_edit = {};
      // console.log("Llego emi, index: ", this.index);
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if (encuesta_actual != this.encuesta) {
        this.encuesta = encuesta_actual;
      }
      this.habilitadoA = true;
      this.habilitadoC = true;
      this.habilitadoEL = true;
      this.habilitadoD = true;
      this.habilitadoS = true;
      this.show_component = "alternativas";
    },

    cancelQuestion(){
      this.index = -1;
      this.pregunta_edit = {};

      this.habilitadoA = true;
      this.habilitadoC = true;
      this.habilitadoEL = true;
      this.habilitadoD = true;
      this.habilitadoS = true;
      
      this.show_component = "alternativas";
    },
    deleteQuestion(index){
      // LocalStorage de eliminadas
      if (this.eliminadas == undefined) {
        this.eliminadas = [];
      }
      this.eliminadas.push(this.encuesta.preguntas[index]);
      localStorage.setItem(
        "preguntas_deleted",
        JSON.stringify(this.eliminadas)
      );
      console.log("preguntas eliminadas:", this.eliminadas);
      // this.addPreguntasDeleted(this.pregunta_eliminada);
      // LocalStorage de encuesta
      this.encuesta.preguntas.splice(index, 1);
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuesta));
    },
    editQuestion(index) {
      console.log("--------------------");
      console.log(`Pregunta ${index}: `, this.encuesta.preguntas[index]);
      let pregunta = this.encuesta.preguntas[index];
      // Desarrollo
      if (pregunta.tipo_pregunta == "1") {
        this.index_pregunta = index;
        this.pregunta_edit = pregunta;
        this.show_component = "desarrollo";
        this.habilitadoA = false;
        this.habilitadoC = false;
        this.habilitadoEL = false;
        this.habilitadoD = true;
        this.habilitadoS = false;
        this.$refs.desarrollo_.refresh(pregunta);
      }
      // Alternativa
      if (pregunta.tipo_pregunta == "2" || pregunta.tipo_pregunta == "3") {
        this.index_pregunta = index;
        this.pregunta_edit = pregunta;
        this.show_component = "alternativas";
        this.$refs.alternativas_.refresh(pregunta);
        this.habilitadoA = true;
        this.habilitadoC = false;
        this.habilitadoEL = false;
        this.habilitadoD = false;
        this.habilitadoS = false;
      }
      // Calificación
      if (
        pregunta.tipo_pregunta == "4" &&
        pregunta.calificacion_estrellas == "1"
      ) {
        this.index_pregunta = index;
        this.pregunta_edit = pregunta;
        this.show_component = "calificacion";
        this.habilitadoA = false;
        this.habilitadoC = true;
        this.habilitadoEL = false;
        this.habilitadoD = false;
        this.habilitadoS = false;
        this.$refs.calificacion_.refresh(pregunta);
      }
      // Escala lineal
      if (
        pregunta.tipo_pregunta == "4" &&
        pregunta.calificacion_estrellas == "0"
      ) {
        this.show_component = "escalalineal";
        this.index_pregunta = index;
        this.pregunta_edit = pregunta;
        this.habilitadoA = false;
        this.habilitadoC = false;
        this.habilitadoEL = true;
        this.habilitadoD = false;
        this.habilitadoS = false;
        this.$refs.escalaLineal_.refresh(pregunta);
      }
      // Satisfacción
      if (pregunta.tipo_pregunta == "5") {
        this.show_component = "satisfaccion";
        this.index_pregunta = index;
        this.pregunta_edit = pregunta;
        this.habilitadoA = false;
        this.habilitadoC = false;
        this.habilitadoEL = false;
        this.habilitadoD = false;
        this.habilitadoS = true;
        this.$refs.satisfaccion_.refresh(pregunta);
      }
    },
    statusComprobar() {
      this.open_comprobar_status = false;
    },
  },
};
</script>
