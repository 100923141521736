<template>

  <section id="form-encuesta-calificacion">
    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="pregunta"
          >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="border-bottom mb-3 pb-3"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Respuesta</label>
        <ul class="list-inline">
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 1"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 1"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 0"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 2"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 2"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 1"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 3"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 3"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 2"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 4"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 4"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 3"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a 
              href="javascript:" 
              class="btn border btn-custom-color-white"
              @click="rango_estrellas = 5"
              >
              <font-awesome-icon 
              v-if="rango_estrellas < 5"
              :icon="['fa-regular', 'star']"
              />
              <font-awesome-icon 
              v-if="rango_estrellas > 4"
              :icon="['fa-solid', 'star']"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="cleanValues()"
          v-if="boton_enviar == 'Editar'"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!comprobar()"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status"
      @close="acceptStatus" 
    />

<!--     <div class="add-encuesta__component-dos">
      <div class="">
            <div class="main-add__first d-flex align-items-end justify-content-between">
        <label for="" class="main-add__label">
          Ingresa la pregunta
          <div class="main-add__input-complete">
            <input
              maxlength="170"
              type="text"
              v-model="pregunta"
            />
          </div>
        </label>
      </div>
      <hr />
        <div class="add-calificacion-container">
          <label for="" class="main-add__text-response">
            Respuesta
            <div class="select-container d-flex">
              <div class="star-active mx-1">
                <font-awesome-icon icon="star"></font-awesome-icon>
              </div>
              <div class="star-active mx-1">
                <font-awesome-icon icon="star"></font-awesome-icon>
              </div>
              <div class="star-active mx-1">
                <font-awesome-icon icon="star" @click="cat_selected = 5"></font-awesome-icon>
              </div>
              <div class="star-active mx-1">
                <font-awesome-icon icon="star" v-show="cat_selected >= 4" @click="cat_selected = 5"></font-awesome-icon>
                <font-awesome-icon :icon="['far', 'star']" v-show="cat_selected == 3" @click="cat_selected = 5">
                </font-awesome-icon>
              </div>
              <div class="star-active mx-1">
                <font-awesome-icon icon="star" v-show="cat_selected >= 5"></font-awesome-icon>
                <font-awesome-icon :icon="['far', 'star']" v-show="cat_selected == 3" @click="cat_selected = 5">
                </font-awesome-icon>
                <font-awesome-icon :icon="['far', 'star']" v-show="cat_selected == 4" @click="cat_selected = 5">
                </font-awesome-icon>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="main-add__buttons-container pb-3">
        <button 
          class="main-add__button-clean" 
          @click="cleanValues()"
          v-if="boton_enviar == 'Editar'"
        >
          Cancelar
        </button>
        <button 
          class="main-add__button-upload" 
          :class="comprobar() && 'active'" 
          @click="addOrEdit()"
        >
          {{ boton_enviar }}
        </button>
      </div>
      <Status 
        v-if="open_modal_status" 
        :msg="modal_status_msg" 
        :status="modal_status"
        @close="acceptStatus" 
      />
    </div> -->
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
export default {
  components: {
    Status,
  },
  props: {
    index: {
      type: Number,
    },
    edit: {
      type: Object,
    },
  },
  data() {
    return {
      pregunta: "",
      opcion: "0",
      // modal status
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      boton_enviar: "Añadir",
      id_pregunta: "0",

      opciones_edit: [],
      open_select: false,
      cat_selected: 5,
      estrellas: [3, 4, 5],

      //FF 
      rango_estrellas: 0
    };
  },
  created() {
    let data = localStorage.getItem("encuestaStore");
    if (data != null) {
      this.encuestaStore = JSON.parse(data);
    }
  },
  methods: {
    refresh(pregunta) {
      console.log("Pregunta editar", pregunta);
      if (
        pregunta.tipo_pregunta == "4" &&
        pregunta.calificacion_estrellas == "1"
      ) {
        this.id_pregunta = pregunta.id_pregunta;
        this.pregunta = pregunta.pregunta;
        this.opciones_edit = pregunta.opciones;
        this.cat_selected = pregunta.opciones.length;
        this.boton_enviar = "Guardar";
      }
    },
    // Limpiar
    cleanValues() {
      this.pregunta = "";
      this.opcion = "0";
      this.boton_enviar = "Añadir";
      this.$emit("cancel");
    },
    // Controlador de añadir o editar
    addOrEdit() {
      if (this.comprobar() == true) {
        if (this.boton_enviar == "Añadir") {
          this.addQuestion();
        } else {
          this.editQuestion();
        }
      }
    },
    addQuestion() {
      if (this.encuestaStore.preguntas == undefined) {
        this.encuestaStore.preguntas = [];
      }
      let pregunta = {
        id_pregunta: "0",
        tipo_pregunta: "4",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "1",
        opciones: this.optionsFormat(),
      };
      this.updateQuestions();
      this.encuestaStore.preguntas.push(pregunta);
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("add-pregunta");
    },
    editQuestion() {
      let pregunta = {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "4",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "1",
        opciones: this.optionsEditFormat(),
      };
      this.updateQuestions();
      this.encuestaStore.preguntas[this.index] = pregunta;
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("edit-pregunta");
      this.boton_enviar = "Añadir";
    },
    optionsFormat() {
      let options = [];
      for (let i = 0; i < 5; i++) {
        options.push({
          id_alternativa: "0",
          genera_qr: "0",
          correcta_alternativa: "1",
          opcion: `${i + 1}`,
        });
      }
      return options;
    },
    optionsEditFormat() {
      let options = this.optionsFormat();
      // cambiando solo ids alternativas antiguas
      if (this.opciones_edit.length <= options.length) {
        for (let i = 0; i < this.opciones_edit.length; i++) {
          options[i].id_alternativa = this.opciones_edit[i].id_alternativa;
        }
      } else {
        for (let i = 0; i < options.length; i++) {
          options[i].id_alternativa = this.opciones_edit[i].id_alternativa;
        }
        for (let i = options.length; i < this.opciones_edit.length; i++) {
          options.push({
            id_alternativa: this.opciones_edit[i].id_alternativa,
            genera_qr: "0",
            correcta_alternativa: "1",
            opcion: "delete",
          });
        }
      }
      console.log("opciones actualizadas: ", options);
      return options;
    },
    updateQuestions() {
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if (encuesta_actual != this.encuestaStore) {
        this.encuestaStore = encuesta_actual;
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },
    // funciones template
    toggleSelect() {
      if (this.open_select) this.open_select = false;
      else this.open_select = true;
    },
    setTipoSeleccion(status) {
      console.log("status: ", status);
      this.cat_selected = status;
      setTimeout(() => {
        this.open_select = false;
      }, 100);
    },
    comprobar() {
      if (this.pregunta != "") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.star-active,
.star-disable {
  color: #0072f7;
}
</style>
