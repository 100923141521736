<template>
  <section id="add-encuesta">
    <SectionTabs :tabs="tab_component"/>
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-5">
          <i class="fa-regular fa-square-check color-secondary"></i>
          Completa los siguientes pasos para subir una noticia.
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="form-step">
                <div 
                  @click="step1()" 
                  class="form-step-item" 
                  :class="handleStepsClass(1)">
                  1
                </div>
                <div 
                  @click="step2()" 
                  class="form-step-item" 
                  :class="handleStepsClass(2)">
                  2
                </div>
                <div 
                  @click="step3()" 
                  class="form-step-item" 
                  :class="handleStepsClass(3)">
                  3
                </div>
            </div>
            <AddComponent
              v-if="showComponent === 1"
              @add-encuesta="nextStep"
            />
            <AddComponent2
              v-if="showComponent === 2"
              @add-encuesta="nextStep"
              @back-encuesta="backStep"
            />
            <AddComponent3
              v-if="showComponent === 3"
              @back-encuesta="backStep"
            />
          </div>
        </div>
      </div>
    </div>

<!--         <div class="news-list__top">
          <div class="news-list-top-filter flex-column">
            
            <div
              class="encuestas-add__steps-buttons"
            >
              <div class="news-add__steps-container">
                <div @click="step1()" class="step" :class="handleStepsClass(1)">1</div>
                <div @click="step2()" class="step" :class="handleStepsClass(2)">2</div>
                <div @click="step3()" class="step" :class="handleStepsClass(3)">3</div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-encuesta__component-uno">
          <div class="seguridad-add">
            <div class="add-encuesta__container flex-column">
              <AddComponent
                v-if="showComponent === 1"
                @add-encuesta="nextStep"
              />
            <AddComponent2
                v-if="showComponent === 2"
                @add-encuesta="nextStep"
                @back-encuesta="backStep"
            />
              <AddComponent3
                v-if="showComponent === 3"
                @back-encuesta="backStep"
              />
            </div>
          </div>
        </div> -->
  </section>

</template>
<script>
import SectionTabs from "../Section/SectionTabs.vue";
import AddComponent from "./AddComponent.vue";
import AddComponent2 from "./AddComponent2.vue";
import AddComponent3 from "./AddComponent3.vue";
//importar mutacion de apollo
export default {
  name: "AddEncuesta",
  components: {
    SectionTabs,
    AddComponent,
    AddComponent2,
    AddComponent3,
  },
  data() {
    return {
      tab_component: [
        {
          name: "Gestiona",
          route: "encuestas-list",
        },
        {
          name: "Crear",
          route: "encuestas-add",
        },
      ],
      showComponent:1,
      component1: true,
      component2: false,
      component3: false,

      subtitulo: "Crea tu encuesta",
    };
  },
  mounted() {
    if (this.$route.name == "encuestas-edit"){
      this.tab_component[1].name = "Editar";
      this.subtitulo = "Edita tu encuesta"
    }
        //  console.log("Ruta add: ", this.$route);
  },
  computed: {
  },
  methods: {
    step1(){
      this.showComponent = 1;
    },
    step2(){
      if(this.component2 == true){
        this.showComponent = 2;
      }
    },
    step3(){
      if(this.component3 == true){
        this.showComponent = 3;
      }
    },
    nextStep() {
      if (this.showComponent === 1) {
        this.component2 = true;
        this.showComponent = 2;
      } else if (this.showComponent === 2) {
        this.component3 = true;
        this.showComponent = 3;
      }
    },
    backStep(){
      if (this.showComponent === 2) {
        this.showComponent = 1;
      } else if (this.showComponent === 3) {
        this.showComponent = 2;
      }
    },
    handleStepsClass(status){
      if(status == 1){
        if (this.showComponent == 1) return 'active';
        else if (this.component1 == true) return 'ok';
        else return '';
      }
      if(status == 2){
        if (this.showComponent == 2) return 'active';
        else if (this.component2 == true) return 'ok';
        else return '';
      }
      if(status == 3){
        if (this.showComponent == 3) return 'active';
        else if (this.component3 == true) return 'ok';
        else return '';
      }
    },
  },
};
</script>
