<template>

  <section id="form-encuesta-desarrollo">
    <div class="row">
      <div class="col-12">
        <label class="form-label text-secondary">Ingresa la pregunta</label>
          <input 
            type="text" 
            class="form-control input-custom"
            maxlength="170"
            v-model="pregunta"
          >
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-12 d-flex align-items-end justify-content-end">
        <button 
          class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
          @click="cleanValues()"
          v-if="boton_enviar == 'Editar'"
          >
          Cancelar
        </button>
        <button
          class="btn btn-custom-color-blue border-round-50 mw-100"
          @click="addOrEdit()"
          :disabled="!comprobar()"
        >
        {{ boton_enviar }}
        <i class="fa-solid fa-arrow-right ps-2"></i>
        </button>
      </div>
    </div>

    <Status 
      v-if="open_modal_status" 
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus" 
    />

<!--     <div class="add-encuesta__component-dos">
      <div class="">
        <div class="main-add__first d-flex align-items-end justify-content-between">
          <label for="" class="main-add__label">
            Ingresa la pregunta
            <div class="main-add__input-complete mt-1">
              <input maxlength="170" type="text" v-model="pregunta" />
            </div>
          </label>
        </div>
        <hr />
      </div>
      <div class="main-add__buttons-container">
        <button class="main-add__button-clean" @click="cleanValues()" v-if="boton_enviar == 'Editar'">Cancelar</button>
        <button class="main-add__button-upload" :class="comprobar() && 'active'" @click="addOrEdit()">
          {{ boton_enviar }}
        </button>
      </div>
      <Status v-if="open_modal_status" :msg="modal_status_msg" :status="modal_status" @close="acceptStatus" />
    </div> -->
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
export default {
  components: { Status },
  props: {
    index: {
      type: Number,
    },
    edit: {
      type: Object,
    }
  },
  data() {
    return {
      pregunta: "",
      preguntas: [],
      // modal status
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,

      boton_enviar: "Añadir",
      id_pregunta: "0",

    };
  },
  created() {
    let data = localStorage.getItem("encuestaStore");
    if (data != null) {
      this.encuestaStore = JSON.parse(data);
    }
  },
  methods: {
    refresh (pregunta){
      console.log("Pregunta editar", pregunta);
      if(pregunta.tipo_pregunta == "1"){
        this.id_pregunta = pregunta.id_pregunta;
        this.pregunta = pregunta.pregunta;
        this.boton_enviar = "Guardar";
      }
    },
    // Limpiar
    cleanValues() {
      this.pregunta = "";
      this.boton_enviar = "Añadir";
      this.$emit("cancel");
    },
    // Controlador de añadir o editar
    addOrEdit() {
      if(this.comprobar() == true){
        if(this.boton_enviar == "Añadir") {
          this.addQuestion();
        } else {
          this.editQuestion();
        }
      }
    },
    addQuestion() {
      if (this.encuestaStore.preguntas == undefined) {
        this.encuestaStore.preguntas = [];
      }
      let pregunta = {
        id_pregunta:"0",
        tipo_pregunta: "1",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: [],
      };
      this.updateQuestions();
      this.encuestaStore.preguntas.push(pregunta);
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      let data = JSON.parse(localStorage.getItem("encuestaStore"));
      console.log("data: ", data.preguntas);
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("add-pregunta");
    },
    editQuestion() {
      let pregunta = {
        id_pregunta: this.id_pregunta,
        tipo_pregunta: "1",
        pregunta: this.pregunta,
        editable: "1",
        calificacion_puntos: "0",
        calificacion_estrellas: "0",
        opciones: [],
      };
      this.updateQuestions();
      this.encuestaStore.preguntas[this.index] = pregunta;
      localStorage.setItem("encuestaStore", JSON.stringify(this.encuestaStore));
      this.cleanValues();
      this.$Progress.finish();
      this.$emit("edit-pregunta");
      this.boton_enviar = "Añadir";
    },
    updateQuestions(){
      let encuesta_actual = JSON.parse(localStorage.getItem("encuestaStore"));
      if(encuesta_actual != this.encuestaStore){
        this.encuestaStore = encuesta_actual;
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },
    comprobar(){
      if(this.pregunta != ""){
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style></style>
